import React from "react";
import "./Home.css";
import ProductSegment from "./ProductSegment";
import AboutSegment from "./AboutSegment";
import Collage from "./collage/Collage";
import SignUpSegment from "./signup/SignUpSegment";
import Footer from "./footer/Footer";

function Home() {
  return (
    <div id="home-container" className="ui container">
      <div id="home-segment" className="ui segment">
        <div id="main-container" className="ui container">
          <div className="ui stackable grid">
            <div className="row">
              <div className="two wide column" />
              <div className="five wide column">
                <h2 className="ui header black-title">100% Hondureño</h2>
                <h1 className="ui huge header red-title">Café los Naranjos</h1>
                <h4 className="ui header black-text">
                  Es producto de las tierras ubicadas en la Zona Oriental de
                  Honduras, en el Departamento de El Paraíso, en un lugar
                  llamado Aldea El Ocotal, jurisdicción de Yuscaran, localizada
                  a una altura aproximada de 1350 metros sobre el nivel del mar.
                  <hr></hr>
                  En el año 2016 se formaliza a través del emprendimiento la
                  marca CAFÉ LOS NARANJOS con la intención de crear un nuevo
                  concepto, dinámico, pero sin perder el famoso "café de palo"
                  que tanto buscan ahora los amantes del café.
                </h4>
              </div>
              <div className="nine wide column">
                <img
                  id="main-img"
                  className="ui centered large image"
                  src="productos_home.jpg"
                />
              </div>
            </div>
            <div className="row">
              <div className="eight wide column">
                <img
                  className="ui centered medium image creme-hn"
                  src="hn_crema.png"
                />
              </div>
            </div>
          </div>
        </div>

        <ProductSegment />
        <AboutSegment />
        <div id="galeria-segment" className="ui segment">
          <h1 className="ui center aligned header creme-text title">
            Galeria!
          </h1>
          <Collage />
        </div>
        <SignUpSegment />
        <Footer/>
      </div>
    </div>
  );
}

export default Home;
