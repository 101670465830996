import Header from "./components/Header"
import Home from "./components/Home"

function App() {
  return (
    <div id="main" className="App">
      <Header alt=""/>
      <Home/>
    </div>
  );
}

export default App;
