import React from "react";
import "./Collage.css";

const Collage = () => {
  const importAll = (r) => {
    return r.keys().map(r);
  };

  const listOfImages = importAll(
    require.context("/public/collage-images/", false, /\.(png|jpe?g|svg)$/)
  );

  const renderGroup = (image, index) => {
    return (
      <div className="media-element">
        <img key={index} src={`${image}`} alt="info"></img>
      </div>
    );
  };

  return (
    <div className="media-scroller media-scroller--with-groups snaps-inline">
      {listOfImages.map((image, index) => {
        return <div className="media-group">{renderGroup(image, index)}</div>;
      })}
    </div>
  );
}

export default Collage;
