import React from "react";

function ProductSegment() {
  return (
    <div className="ui container">
      <div className="ui stackable grid">
        <div className="thirteen wide column">
          <div id="product-segment" className="ui segment">
            {/*SEGMENTO DE PRODUCTO*/}
            <div className="ui stackable grid">
              <div className="three column row">
                <div className="column">
                  <h2 className="ui header orange-title title">
                    Presentación de producto
                  </h2>
                </div>
              </div>
            </div>

            <div className="ui stackable grid">
              <div className="nine wide column">
                <img id="product-img"
                  className="ui centered large image"
                  src="cafe_hojas.jpg"
                />
              </div>

              <div className="six wide column vert-align">
                <h2 className="ui left aligned header creme-text">
                  Bolsa de Café
                </h2>
                <h4 className="ui left aligned header orange-title">
                  Presentación de 14 oz
                </h4>
                <h3 className="ui header creme-text">
                  Café Los Naranjos se comercializa en 2 presentaciones: 
                  <hr className="thin"></hr>
                  1. Café
                  molido.
                  <hr className="thin"></hr>
                  2. Café en grano.
                </h3>
              </div>
            </div>
            {/*FIN DE SEGMENTO DE PRODUCTO*/}
          </div>
        </div>
        <div className="three wide column">
          <img className="ui image creme-logo-img" src="creme_logo.png" />
        </div>
      </div>
    </div>
  );
}

export default ProductSegment;
