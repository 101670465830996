import React from "react";

const SignUpField = (props) => {
  const { name, label, type, required, register, errors } = props;
  const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i;

  return (
    <div className="omrs-input-group">
      <label htmlFor={name} className="omrs-input-filled">
        {/*label*/}
        <input
          name={name}
          required={required}
          id={name}
          type={type}
          {...name === "email"
          ? {
              ...register(name, {
                pattern: {
                  value: EMAIL_REGEX,
                  message: "Dirección de correo inválido",
                }
              })
            }
          : { ...register(name) }}
        />
        <span className="omrs-input-label">{label}</span>
      </label>
      {errors[name] && (
        <div className="ui header red-text">{errors[name].message}</div>
      )}
    </div>
  );
};

export default SignUpField;
