import axios from "axios";
import React from "react";
import SignUpField from "./SignUpField";
import SignUpForm from "./SignUpForm";
import "./SignUpSegment.css";

const SignUpSegment = () => {
  const handleValidSubmit = async (event, values) => {
    console.log("submit",values);
    const {nombre,apellido,email} = values;
    try{
        const payload = {
            merge_fields:{
                FNAME: nombre,
                LNAME: apellido
            },
            email_address:email
        }
        await axios.post('/netlify/functions/registerEmail.js',payload);
        alert('Fuiste agregado con exito!');
    }catch(error){
        console.log(error);
    }
  };

  return (
    <div id="signup-segment" className="ui segment">
      <h1 className="ui center aligned header creme-text">
        Suscríbete a nuestro boletín
      </h1>
      <div className="ui two column centered grid">
        <div className="column">
          <div id="form-container" className="ui container">
            <SignUpForm >
            </SignUpForm>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpSegment;
