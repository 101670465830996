import React from "react";
import "./Footer.css";
import ReactWhatsapp from "react-whatsapp";

const onClickScrollHome = (e) => {
  e.preventDefault();
  const anchor = document.querySelector("#main");
  anchor.scrollIntoView({ behavior: "smooth" });
};

const onClickScrollProduct = (e) => {
  e.preventDefault();
  const anchor = document.querySelector("#product-segment");
  anchor.scrollIntoView({ behavior: "smooth" });
};

const onClickScrollAbout = (e) => {
  e.preventDefault();
  const anchor = document.querySelector("#about-segment");
  anchor.scrollIntoView({ behavior: "smooth" });
};

const onClickScrollGallery = (e) => {
  e.preventDefault();
  const anchor = document.querySelector("#galeria-segment");
  anchor.scrollIntoView({ behavior: "smooth" });
};

const Footer = () => {
  return (
    <div className="ui vertical footer segment">
      <div className="ui center aligned container">
        <div className="ui stackable grid">
          <div className="five wide column">
            <h4 className="ui header">Segmentos</h4>
            <div className="ui link list">
              <a className="item" href="/" onClick={onClickScrollHome}>
                Home
              </a>
              <a className="item" href="/" onClick={onClickScrollProduct}>
                Productos
              </a>
              <a className="item" href="/" onClick={onClickScrollAbout}>
                Nuestra Historia
              </a>
              <a className="item" href="/" onClick={onClickScrollGallery}>
                Galeria
              </a>
            </div>
          </div>
          <div className="six wide column">
            <h4 className="ui header">Contáctanos</h4>
            <a>
              <ReactWhatsapp
                id="whapp-btn"
                className="ui positive button"
                number="50499706244"
                message="Buen día, estoy interesado en su café."
              >
                <i className="whatsapp icon"></i>
                Whatsapp
              </ReactWhatsapp>
            </a>
            <p> Siguenos en nuestras redes sociales.</p>
            <a
              href="https://www.facebook.com/cafelosnaranjos21"
              target="_blank"
            >
              <button type="submit" className="ui medium facebook button">
                <i className="facebook icon"></i>
                Facebook
              </button>
            </a>
            <a
              href="https://www.instagram.com/cafelosnaranjos21/"
              target="_blank"
            >
              <button type="submit" className="ui medium instagram button">
                <i className="instagram icon"></i>
                Instagram
              </button>
            </a>
          </div>
          <div className="five wide column">
            <img
              src="elemento_visual_flores.png"
              className="ui centered small image"
            />
            <div className="ui horizontal small divided link list">
              <h4 className="ui header">Incoyu@gmail.com</h4>
              <h3 className="ui header">© 2022 Café los Naranjos</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
