import React from "react";
import "./AboutSegment.css";

function AboutSegment() {
  return (
    <div id="about-segment">
      <div className="ui stackable grid">
        <div className="three column row">
          <div className="column" />
          <div className="column" />
          <div className="column">
            <h2 className="ui header title">Nuestra Historia</h2>
          </div>
        </div>
        <div className="row">
          <div className="nine wide column">
            <img className="ui centered large image" src="yuscaran.jpg" />
          </div>
          {/* TEXTO DE YUSCARAN*/}
          <div className="six wide column">
            <h4 id="about-text" className="ui justified header black-text">
              La finca el Naranjo fue adquirida por el ilustre Alejandro Alfaro
              Arriaga allá por los años 1950, originalmente llamada finca "El
              Cafetal", posteriormente Don Ale, como cariñosamente le decían, la
              bautizo con el nombre de "Finca El Naranjo", surgiendo de aquí
              Café Los Naranjos, puesto que es un café de tradición familiar.{" "}
              <br />
              <br />
              Durante décadas el café se vendió localmente, en uva, lavado o
              pergamino seco, tanto por Don Ale como por su hija, Alejandrina
              Alfaro Márquez y su esposo Armando Flores Carias, que, siguiendo
              el legado, mantuvieron vivo el cultivo de café, con el objetivo de
              que la Finca El Naranjo perdurara en el tiempo y pasara a las
              siguientes generaciones. <br />
              <br />
              Fue así que en el año 2010 nace la idea de impulsar la producción,
              procesamiento y comercialización del café, reto que fue asumido
              por Carlos Flores Alfaro, nieto de Alejandro Alfaro Arriaga. Es
              así que en el año 2016 se formaliza a través del emprendimiento la
              marca CAFÉ LOS NARANJOS con la intención de crear un nuevo
              concepto, dinámico, pero sin perder el famoso "café de palo" que
              tanto buscan ahora los amantes del café.
            </h4>
            {/***/}
          </div>
        </div>
        <div className="row">
          <div className="ten wide centered column">
            <div className="ui segment" style={{background: '#de4f24'}}>
              <h2 className="ui left aligned header white-title">Yuscarán</h2>
              <h3 id="about-city-text" className="ui header black-text">
                "Yuscarán, es una linda ciudad colonial ubicada en las laderas
                de La Reserva Biológica de Montserrat, zona oriental de Honduras
                y es la cabecera del departamento de El Paraiso. El nombre de
                Yuscarán proviene de un vocablo indigena Mexicano que significa
                “Lugar de casa de Flores”. Posee una extensión territorial de
                348.9 km², su población sobrepasa los 13,000 habitantes y su
                división politica consta de 19 aldeas y 83 caserios."<br/>
                <a
                  href="https://www.xplorhonduras.com/yuscaran-el-paraiso/"
                  target="_blank"
                >
                  -XplorHonduras, 2018
                </a>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutSegment;
