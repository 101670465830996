import React, { Children, cloneElement } from "react";
import { useForm } from "react-hook-form";
import {
  useNetlifyForm,
  NetlifyFormProvider,
  NetlifyFormComponent,
  Honeypot,
} from "react-netlify-forms";
import SignUpField from "./SignUpField";

const SignUpForm = () => {
  //Components hooks
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  //netlify hook
  /*const netlify = useNetlifyForm({
    name: "react-hook-form",
    action: "/thanks",
    honeypotName: "bot-field",
    onSuccess: (response, context) => {
      console.log("Successfully sent form data to Netlify Server");
    },
  });

  const onSubmitForm1 = (data) => netlify.handleSubmit(null, data);
  console.log(children, "children"); */

  const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
  }

  const onSubmitForm2 = handleSubmit((data, e) => {
    console.log(data,"data");
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "newsletterV1", ...data })
    })
      .then(() => alert("Success!"))
      .catch(error => alert(error));

    e.preventDefault();
  });

  /*const renderChildren = () => {
    return (
      <>
        {Children.map(children, (child) => {
          console.log(child, "child");
          return <>{cloneElement(child, { register, errors })}</>;
        })}
      </>
    );
  }; */

  return (
    <div>
      <form name="newsletterV1" method="POST" data-netlify="true" onSubmit={onSubmitForm2}>
        <input type="hidden" name="form-name" value="newsletterV1" />

        <SignUpField
          register={register}
          name="nombre"
          label="Nombre"
          type="nombre"
          required
          errors={errors}
        />
        <SignUpField
          register={register}
          name="apellido"
          label="Apellido"
          type="apellido"
          required
          errors={errors}
        />
        <SignUpField
          register={register}
          name="email"
          label="Email"
          type="email"
          required
          errors={errors}
        />
        <button type="submit" id="submit-form-btn">
          Enviar
        </button>
      </form>
    </div>
  );
};

export default SignUpForm;

/**name="newsletter"
      method="post"
      data-netlify="true"
      onSubmit={handleSubmit(onSubmitForm)} */
