import React from "react";
import { useState } from "react";
import "./Header.css";

function Header() {

  const [visible,setVisible] = useState(false);

  const onClickScrollProduct = (e) => {
    setVisible(false);
    e.preventDefault();
    const anchor = document.querySelector("#product-segment");
    anchor.scrollIntoView({ behavior: "smooth"});
  };

  const onClickScrollAbout = (e) => {
    setVisible(false);
    e.preventDefault();
    const anchor = document.querySelector("#about-segment");
    anchor.scrollIntoView({ behavior: "smooth"});
  };

  const onClickScrollGallery = (e) => {
    setVisible(false);
    e.preventDefault();
    const anchor = document.querySelector("#galeria-segment");
    anchor.scrollIntoView({ behavior: "smooth"});
  };

  const changeVisibility = (e) => {
    setVisible(!visible);
  };

  let atr = visible ? 'data-visible': '';

  return (
    <div id="header-container" className="">
      <div id="header" className="flex">
        <img className="ui small image navbar-logo" src="creme_logo.png" />
        <button
          className="mobile-nav-toggle"
          aria-controls="primary-navigation"
          aria-expanded={visible}
          onClick={changeVisibility}
        >
         {/* <span className="sr-only">Menu</span> */}
        </button>

        <nav>
          <ul id="primary-navigation" className={`primary-navigation flex ${atr}`}>
            <li className="active">
              <a href="/">
                <span aria-hidden="true">01</span>Home
              </a>
            </li>
            <li className="">
              <a href="/" onClick={onClickScrollProduct}>
                <span aria-hidden="true">02</span>Productos
              </a>
            </li>
            <li className="">
              <a href="/" onClick={onClickScrollAbout}>
                <span aria-hidden="true">03</span>Nosotros
              </a>
            </li>
            <li className="">
              <a href="/" onClick={onClickScrollGallery}>
                <span aria-hidden="true">04</span>Galeria
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <hr className="color-yellow" />
    </div>
  );
}

export default Header;

// <img src="logo_naranjos.jpg"/>
